import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import Breadcrumb from '../../../../components/Breadcrumb';
import ContentImage from '../../../../components/ContentImage';
import {lang} from '../../../../helpers/lang';

const Agriculture = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			cropMonitoring: file(
				relativePath: {
					eq: "pages/work/sectors/agriculture/crop_grassland_monitoring.png"
				}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			agriculturalMonitoring: file(
				relativePath: {
					eq: "pages/work/sectors/agriculture/agricultural_monitoring.png"
				}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			smartFarming: file(
				relativePath: {eq: "pages/work/sectors/agriculture/smart_farming.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			background: file(
				relativePath: {eq: "pages/work/sectors/agriculture.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout theme={pageContext.sector.theme}>
			<SEO title={pageContext.sector.title} lang={lang} />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>Agriculture</HighlightedTitle>
				<HighlightedParagraph>
					Agricultural monitoring and food production assessment serving
					farmers, industry, governments and agencies.
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#crop-grassland-monitoring'}>
								Crop & grassland monitoring
							</a>
							<a href={'#smart-farming'}>Smart farming</a>
							<a href={'#agricultural-monitoring-to-support-CAP-controls'}>
								Agricultural monitoring to support CAP controls
							</a>
						</Anchors>

						<PersonBadge
							note={pageContext.sector.contactNote}
							email={pageContext.sector.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						Farming is a complex business. Key agriculture challenges - feeding
						a growing population, providing a livelihood for farmers, and
						protecting the environment – currently face serious challenges
						related to climate change, soil erosion and biodiversity loss.
						Increasingly volatile weather and more extreme events change growing
						seasons, limit the availability of water, allow weeds, pests and
						fungi to thrive – all and all challenging the crop productivity and
						economic profitability. At the same time, the world’s farmland is
						shrinking.
					</p>
					<p>
						New approaches and technologies are therefore inevitable for farmers
						and the agriculture industry to adapt to these changing economic,
						ecologic and social factors. Our Earth observation solutions for
						operational agricultural monitoring and food production assessment
						provide support for such an adaptation.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'crop-grassland-monitoring'}>Crop & grassland monitoring</h2>
					<p>
						Services implementing diverse aspects of agricultural monitoring.
						They focus on cropland extent and crop type mapping to support
						agricultural statistics, crop yield estimation and forecasting, crop
						health and disease assessment, crop damage and agricultural drought
						monitoring.They also enable monitoring of farming practices, crop
						rotation, grassland management or vegetation stress and phenology
						assessment.
					</p>
					<p>
						The services provide regional, nationwide or continental information
						about the cultivated areas and crop conditions to support the
						implementation of agriculture administration & statistics system and
						decision making in trading on agricultural commodity markets, food
						security, agricultural risk insurance, climate change impacts
						assessment and many other sectors.
					</p>
					<ContentImage
						fluid={
							data
								? data.cropMonitoring.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						// legend={<>Image description</>}
					/>
					{/* <h4>Case studies</h4> */}
					{/* <div>
				Reference projects:
				<br/>
				N2K, ETC Phenology, SIGMA
			</div> */}

					<h2 id={'smart-farming'}>Smart farming</h2>
					<p>
						Services supporting the implementation of precision farming and
						site-specific farm management. They are based on analysis of
						satellite imagery to monitor field heterogeneity and to enable the
						application of variable fertilization, seeding and irrigation. Also,
						a wide range of decision makings in farm management is supported,
						including the planning of plant protection, harvest management,
						irrigation planning, drought and soil erosion assessment.
					</p>
					<p>
						Our goal is fulfilling the mission to provide affordable and
						worldwide services that bring considerable added value to farmers,
						especially in increasing the farming efficiency, decreasing the
						costs and ensuring compliance with environmental requirements for
						farming.
					</p>
					<p>
						The smart farming services are provided in collaboration with
						CleverFarm company that contributes with IoT monitoring services and
						farming consultations.
					</p>
					<ContentImage
						fluid={
							data
								? data.smartFarming.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						// legend={<>Image description</>}
					/>
					{/* Reference projects:AGRITAS*/}

					<h2 id={'agricultural-monitoring-to-support-CAP-controls'}>
						Agricultural monitoring to support CAP controls
					</h2>
					<p>
						Services supporting the operation of Checks by Monitoring to be
						implemented as part of the Area Monitoring System (AMS) within the
						framework of the Common Agricultural Policy (CAP). They are based on
						analysis of satellite imagery to enable operational full country
						monitoring of various cropping and agronomic practices.
					</p>
					<p>
						The assessment aims at ensuring the conformity of farmers
						declarations with the real situation on each declared parcel,
						including verification of declared crops, compliance with various
						environmental requirements (e.g. catch crops, nitrogen fixing crops,
						fallow land), growing of specific crops (e.g. hemp, protein crops),
						grassland management (ploughing, mowing, grazing) or monitoring of
						permanent crops (e.g. vineyards, orchards, hops). Also, diverse
						services to improve the quality of the Land Parcel Information
						System (LPIS) and the LPIS update process are part of the solution.
					</p>
					<p>
						The services are provided to Paying Agencies in EC countries to
						support their decision making within the modernised Integrated
						Administration and Control Systems (IACS). They contribute to
						increasing the quality, efficiency and traceability of the AMS
						control processes as well as reducing the costs of the AMS
						operation.
					</p>
					<ContentImage
						fluid={
							data
								? data.agriculturalMonitoring.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						// legend={<>Image description</>}
					/>
					{/* Reference projects:Sen4CAP, DROMAS*/}
				</Content>
			</Block>
		</Layout>
	);
};

Agriculture.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default Agriculture;
